import React, { useState } from "react";

const AboutPractice = ({ image, header, children, forceExpand = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="w-full flex justify-center items-center py-12 bg-white border-b">
      <div className="w-2/3 mt-4 h-full flex flex-row flex-wrap-reverse justify-center items-end">
        <img alt="staff1" className="w-64 h-full object-contain" src={image} />
        <div
          className={`w-72 mb-4 relative ${
            isExpanded || forceExpand ? "h-auto" : "h-70 overflow-hidden"
          }`}
        >
          <div
            className={`w-full flex flex-col justify-start ${
              isExpanded || forceExpand ? "h-auto" : "h-68 overflow-hidden"
            }`}
          >
            <div className="px-4">
              <div className="w-full border-b">
                <p className="text-2xl font-serif text-sunstate-primary text-center pb-4 tracking-wider">
                  {header}
                </p>
              </div>
              <div className="overflow-hidden text-lg text-left text-gray-600">
                {children}
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 left-0 pl-4">
            <button
              className={`border-b-2 border-sunstate-primary text-lg text-sunstate-primary hover:text-gray-600 uppercase font-semibold ${
                isExpanded || forceExpand ? "hidden" : "inline-block"
              }`}
              onClick={() => setIsExpanded(true)}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPractice;
