import React from "react";
import Layout from "../../components/Layout";
import PageHeader from "../../components/PageHeader";
import ProviderInfo from "../../components/ProviderInfo";

import pothen from "../../img/pothen.jpg";

const PothenKoruthMd = () => {
  return (
    <Layout>
      <PageHeader text="Pothen Koruth, MD" />
      <ProviderInfo header="About Dr. Koruth" image={pothen} forceExpand>
        <p className="pt-5">
          Pothen C. Koruth, MD, is a board-certified internist with over 30
          years of combined experience, currently caring for patients at
          Sunstate Medical Associates in Lake Mary, Florida.
        </p>
        <p className="mt-6">
          Dr. Koruth received his medical degree from Calicut Medical College in
          Kozhikode, India, where he also completed training in internal
          medicine. In addition, he trained at Mt. Sinai Medical Center - Case
          Western Reserve University in Cleveland, Ohio, followed by a
          residency.
        </p>
        <p className="mt-6">
          Dr. Koruth started a medical practice in California and later moved to
          Central Florida to help establish one of the first full-fledged
          hospitalist programs in the country. In 1999, he teamed up with Dr.
          Farideh A. Zadeh, a long-standing professional colleague, to start
          Sunstate Medical Associates.
        </p>
        <p className="mt-6">
          He is certified by the American Board of Internal Medicine and
          maintains affiliations with numerous hospitals in Florida. His vast
          outpatient experience and recent work as a hospitalist make him a
          highly sought-after physician at the practice.
        </p>
        <p className="mt-6">
          Dr. Koruth enjoys welcoming all patients at SunState Medical
          Associates.
        </p>
      </ProviderInfo>
    </Layout>
  );
};

export default PothenKoruthMd;
